import Vue from 'vue';

const EventBus = new Vue();

const mapNotificationEventActions = {
    emitNotification( { type, msg, title }) {
        switch (type) {
            case 1:  EventBus.$toast.success(msg, title); break;
            case 2:  EventBus.$toast.info(msg, title);    break;
            case 3:  EventBus.$toast.warning(msg, title); break;
            case 4:  EventBus.$toast.show(msg, title);    break;
            default: EventBus.$toast.error(msg, title);
        }
    },
    emitNotImplemented( payload = {}) {
        let defaultPayload = {
            msg:   'function is not yet written',
            title: 'Not Yet Implemented'
        };
        this.emitWarning({ ...defaultPayload, ...payload });
    },
    emitError( payload = {} ) {
        let defaultPayload = {
            msg:   'Error',
            title: 'Error'
        };
        this.emitNotification({ ...defaultPayload, ...payload, ...{type: 0} });
    },
    emitSuccess ( payload = {} ) {
        let defaultPayload = {
            msg:   'Success'
        };
        this.emitNotification({ ...defaultPayload, ...payload, ...{type: 1} });
    },
    emitInfo ( payload = {} ) {
        let defaultPayload = {
            msg:   'Info'
        };
        this.emitNotification({ ...defaultPayload, ...payload, ...{type: 2} });
    },
    emitWarning( payload = {} ) {
        let defaultPayload = {
            msg:   'Warning'
        };
        this.emitNotification({ ...defaultPayload, ...payload, ...{type: 3} });
    },
    emitShow ( payload = {} ) {
        let defaultPayload = {
            msg:   'Show'
        };
        this.emitNotification({ ...defaultPayload, ...payload, ...{type: 4} });
    },
};

export {
  EventBus,
  mapNotificationEventActions
}
