import Vue from 'vue'
import Vuex from 'vuex'

import auth   from './auth'
import recipe from './recipe'

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        recipe
    }
})

