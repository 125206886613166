import axios from 'axios';

function defaultRequestConfig(token) {
    return {
        'baseURL': process.env.VUE_APP_API_URL,
        'headers': {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json'
        }
    };
}

export default {
  /* *********************************************************************
   * Authentication Routines
   * ********************************************************************* */
  login (email, password) {
    return axios.post(`${process.env.VUE_APP_API_URL}/api/login`, {
      'email': email, 
      'password': password 
    });
  },

  logout (token) {
    return axios.post(`/api/logout`, defaultRequestConfig(token));
  },

  getUserProfile (token) {
    return axios.get(`/api/profile`, defaultRequestConfig(token));
  },

  /* *********************************************************************
   * Recipe Routines
   * ********************************************************************* */
  fetchRecipes (token, filter) {
    return axios.get(`/api/recipe/` + filter, defaultRequestConfig(token));
  },

  fetchRecipe (token, recipeId) {
    return axios.get(`/api/recipe/${recipeId}`, defaultRequestConfig(token));
  },

  saveRecipe (token, recipe) {
    return (recipe._id)?axios.put(`/api/recipe/${recipe._id}`, recipe, defaultRequestConfig(token)):axios.post(`/api/recipe/`, recipe,  defaultRequestConfig(token));
  }
}
