import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Home', props: true, component: () => import('@/components/RecipeList') },
  { path: '/login', name: 'login', component: () => import('@/components/Login') },
  { path: '/logout', name: 'logout', component: () => import('@/components/Logout') },
  { path: '/recipe/new', name: 'RecipeEdit', component: () => import('@/components/RecipeEdit'), props: true },
  { path: '/recipe/:id', name: 'Recipe', component: () => import('@/components/Recipe'), props: true },
  { path: '/recipe/:id/edit', name: 'RecipeEdit', component: () => import('@/components/RecipeEdit'), props: true },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
