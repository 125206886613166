import moment from 'moment';

const DEBUG = (process.env.NODE_ENV == 'development');
const DISABLE_CHATTER = (DEBUG)?((process.env.VUE_APP_DISABLE_CHATTER)?true:false):false;

const mapDebugActions =  {
  debugMessage(msg, source=null) {
    if (DEBUG) console.debug("%c[DEBUG]%c " + moment().format() + " " + ((source)?source:((this.$options)?this.$options.name:'unknown')) + " ==> " + msg, "padding: 5px; background-color: #0095ff; color: #fff;", "padding: 5px; color: #07c;");
  },

  /*
    Usage:
    this.debugObject({object: object})
   */
  debugObject(payload, source=null) {
    if (DEBUG) console.debug({type: 'DEBUG', source: (source)?source:((this.$options)?this.$options.name:'unknown'), time: moment().format(), ...payload})
  },

  disableChatter() {
    return DISABLE_CHATTER;
  }
}

export {
  DEBUG,
  mapDebugActions
}
