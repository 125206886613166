import api from '@/lib/api';
import Vue from 'vue';

function initialState() {
  return {
    recipes: [],
    currentRecipe: {}
  }
}

const state = initialState;

const getters = {
  recipes: (state) => state.recipes,
  currentRecipe: (state) => state.currentRecipe
}

const actions = {
  fetchRecipes: ({commit, rootGetters}, filter) => {
    return new Promise((resolve, reject) => {
      if (rootGetters['auth/apiToken']) {
        api.fetchRecipes(rootGetters['auth/apiToken'], filter)
           .then( (response) => {
             commit ('setRecipes', response.data.recipes);
             resolve(true);
           }).catch((err) => reject(err));
      } else reject( new Error( ( !rootGetters['auth/apiToken'] ) ? 'You are no longer logged in.' : 'Missing required parameter.' ) );
    });
  },
  fetchRecipe: ({commit, rootGetters}, recipeId) => {
    return new Promise((resolve, reject) => {
      if (rootGetters['auth/apiToken'] && recipeId) {
        api.fetchRecipe(rootGetters['auth/apiToken'], recipeId)
           .then( (response) => {
             commit ('setCurrentRecipe', response.data.recipe);
             resolve(true);
           }).catch((err) => reject(err));
      } else reject( new Error( ( !rootGetters['auth/apiToken'] ) ? 'You are no longer logged in.' : 'Missing required parameter.' ) );
    });
  },
  saveCurrentRecipe: ({commit, rootGetters, getters}) => {
    return new Promise((resolve, reject) => {
      if (rootGetters['auth/apiToken']) {
        api.saveRecipe(rootGetters['auth/apiToken'], getters.currentRecipe)
           .then( (response) => {
             commit ('setCurrentRecipe', response.data.recipe);
             resolve(true);
           }).catch((err) => reject(err));
      } else reject( new Error( ( !rootGetters['auth/apiToken'] ) ? 'You are no longer logged in.' : 'Missing required parameter.' ) );
    });
  },
  blankRecipe: ({commit}) => {
    return new Promise((resolve) => {
      commit ('setCurrentRecipe', { "conversion": { "complete": true }, "categories": [], "instructions": [], "name": "", "servings": "", "ingredients": [], "flags": { "paleo": false, "freezable": false, "quick": false, "microwavable": false, "vegetarian": false, "glutenFree": false, "glutenFreeAdaptable": false }, "variations": [] });
      resolve(true);
    });
  }
};

const mutations = {
  setRecipes: (state, recipes) => {
    Vue.set(state, 'recipes', recipes);
  },
  setCurrentRecipe: (state, recipe) => {
    Vue.set(state, 'currentRecipe', recipe);
  },
  resetState (state) {
    const s = initialState();
    Object.keys(s).forEach(key => {
      Vue.set(state, key, s[key]);
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
