import api from '@/lib/api';
import Vue from 'vue'
import JwtDecode from 'jwt-decode'
import moment from 'moment';

function initialState() {
  return {
    token: localStorage.getItem('api_token_client') || '',
    user: {}
  };
}

const state = initialState;

const getters = {
    apiToken: (state, getters) => (getters.isLoggedIn)?state.token:null,
    isLoggedIn(state) {
        if (state.token) {
            const { exp } = JwtDecode(state.token);
            if ( exp > moment().unix() ) return true;
        }

        // Wipe token if exists;
        localStorage.removeItem('api_token_client');
        state.token = '';
        return false;
    },
    currentUserId(state) {
        if (state.token) {
            const { sub } = JwtDecode(state.token);
            return sub;
        }
        return null;
    },
    user: (state) => state.user
};

const actions = {
  login ({ commit }, {email, password} ) {
    return new Promise((resolve, reject) => {
      api.login(email, password)
         .then((response) => {
           if (response.data && response.data.token) {
             commit('setToken', response.data.token);
             resolve(true);
           } else {
             commit('setToken', '');
             reject(new Error('Unrecognized Server Response'));
           }
          })
          .catch( (err) => {
            commit('setToken', '');
            reject(err);
          });
    });
  },
  /* refreshToken ({getters, commit}) {
    return new Promise((resolve, reject) => {
      api.refreshToken(getters.apiToken)
         .then( (response) => {
           if (response.data && response.data.token) {
             commit('setToken', response.data.token);
             resolve(true);
           } else reject(new Error('Unrecognized Server Response'));
         }).catch( (err) => { reject(err) });
    });
  }, */
  currentUser ({getters, commit}) {
    return new Promise((resolve, reject) => {
      api.getUserProfile(getters.apiToken)
         .then( (response) => {
           commit('setCurrentUser', response.data.user);
           resolve(true);
         }).catch((err) => { reject(err) });
    });
  },
  logout ({getters, commit, dispatch}) {
    if (getters.apiToken != null) api.logout(getters.apiToken);

    commit('setToken', '');

    // Wipe the data in the other stores
    dispatch('resetAuthState', {}, {root: true});
    dispatch('resetBroadcastsState', {}, {root: true});
    dispatch('resetMessagesState', {}, {root: true});
    dispatch('resetProgramsState', {}, {root: true});
    dispatch('resetSurveysState', {}, {root: true});
    dispatch('resetUsersState', {}, {root: true});
  },
  resetAuthState({commit}) {
    commit('resetState');
  }
}

const mutations = {
  setToken: (state, token) => {
    Vue.set(state, 'token', token);
    if (state.token) localStorage.setItem('api_token_client', state.token);
    else localStorage.removeItem('api_token_client');
  },
  setCurrentUser: (state, user) => {
    Vue.set(state, 'user', user);
  },
  resetState ( state ) {
    const s = initialState();
    Object.keys(s).forEach(key => {
      Vue.set(state, key, s[key]);
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
