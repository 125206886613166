<template>
  <div id="app">
    <b-navbar type="dark" variant="success">
      <b-navbar-brand href="#">Cookbook</b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav v-if="isLoggedIn">
          <b-nav-item to="/">Home</b-nav-item>
          <b-nav-item to="/recipe/new">New Recipe</b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav v-if="isLoggedIn" class="ml-auto">
          <b-nav-item-dropdown right>
            <template #button-content>
              {{user.firstname}} {{user.lastname}}
            </template>
            <b-dropdown-item to="/logout">Sign Out</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <router-view :filter="filter" @input="(e) => this.filter=e" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { EventBus, mapNotificationEventActions } from '@/lib/events.js';
import { mapDebugActions } from '@/lib/debug.js';

export default {
  name: "App",
  data() {
    return {
      initializedApp: false,
      filter: ''
    };
  },
  computed: {
    ...mapGetters({ "apiToken": "auth/apiToken", "isLoggedIn": "auth/isLoggedIn", "user": "auth/user" })
  },
  beforeDestroy() {
    this.debugMessage('Unloading Event Listeners');
    EventBus.$off('login-event', this.onLoginEvent);
    EventBus.$off('logout-event', this.onLogoutEvent);
  },
  mounted () {
    this.debugMessage('Loading Event Listeners');
    EventBus.$on('login-event', this.onLoginEvent);
    EventBus.$on('logout-event', this.onLogoutEvent);
  },
  updated() {
    this.checkCurrentLogin();
  },
  methods: {
    ...mapActions({ "logout": "auth/logout", "currentUser": "auth/currentUser" }),
    ...mapNotificationEventActions,
    ...mapDebugActions,
    checkCurrentLogin () {
      let redirect = (this.$route.query.redirect) ? this.$route.query.redirect : null;
      this.onLoginEvent(redirect);

      if (this.$route.name == "login") {
        if (this.isLoggedIn) this.$router.push("/");
      } else if (this.$route.meta.noLoginRequired == true) {
        ////
        // Fall through for routes that don't require login
        ////
      } else {
        if (!this.isLoggedIn && this.$route.name != null) {
          this.initializedApp = false;
          if (this.$route.path != '/') this.$router.push("/login?redirect=" + this.$route.path);
          else this.$router.push('/login');
        }
      }
    },
    onLogoutEvent () {
      if (this.isLoggedIn) {
        this.debugMessage ('Logout Event');
        this.logout();
      }
    },
    onLoginEvent (redirect) {
      if (this.isLoggedIn && !this.initializedApp) {
        this.debugMessage('login-event received, initializing currentUser');

        this.initializedApp = true;
        this.currentUser();

        if (redirect) this.$router.push(redirect);
      }
    }
  }
}
</script>

<style>
@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }
}
</style>
